<template>
  <v-dialog v-model="modal" max-width="580px">
    <template v-slot:activator="{ on }">
      <v-btn class="mt-2 primary" slot="activator" v-on="on" :disabled="station.status === 0 ? true : false">Static commands</v-btn>
    </template>

    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5 text-medium-emphasis">
          Static commands
        </div>
        <v-btn density="compact" icon @click="handleModalClose()">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        <!-- Only hex -->
        <v-select v-model="satelliteId" :items="satellites" item-text="name" item-value="id" label="Select a satelliteId*"></v-select>
        <v-row v-if="this.version === 2">
          <v-row class="pt-3 pb-4" align="center" justify="center">
            <v-btn v-for="command in version2Commands" :key="command.commandId" outlined color="primary" class="ma-2 white--text" :disabled="satelliteId==='' || isSubmiting===true" @click="sendStaticCommand(command.commandId)">
              {{ command.command }}
              <v-icon right>mdi-access-point</v-icon>
            </v-btn>
          </v-row>
        </v-row>
        <!-- NOTE: Don't change -->
        <v-row v-else-if="this.selectedSatellite?.callSign === 'OWL1'">
          <v-row class="pt-3 pb-4" align="center" justify="center">
            <v-btn v-for="(command ,idx) in obs1Commands" :key="`${command.commandId}-${idx}`" outlined color="primary" class="ma-2 white--text" :disabled="satelliteId==='' || isSubmiting===true" @click="sendCommandToObc1(command.commandId)">
              {{ command.commandName }}
              <v-icon right>mdi-access-point</v-icon>
            </v-btn>
          </v-row>
        </v-row>
        <!-- NOTE: Don't change -->
        <v-row v-else-if="this.selectedSatellite?.callSign === 'OWL2'">
          <v-row class="pt-3 pb-4" align="center" justify="center">
            <v-btn v-for="(command ,idx) in obs2Commands" :key="`${command.commandId}-${idx}`" outlined color="primary" class="ma-2 white--text" :disabled="satelliteId==='' || isSubmiting===true" @click="sendCommandToObc2(command.commandId)">
              {{ command.commandName }}
              <v-icon right>mdi-access-point</v-icon>
            </v-btn>
          </v-row>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { useApi } from '@hooks/useApi';

const API = useApi();


export default {
  name: "StaticCommand",
  props: [
    "station",
    "satellites",
    "modemConf",
  ],
  data() {
    return {
      modal: null,
      satelliteId: null,
      selectedSatellite: null,
      config: '',
      obs1Commands: [],
      obs2Commands: [],
      version2Commands: [],
      isSubmiting: false,

      version: null,
    }
  },
  beforeMount() {
    this.getVersion2StaticCommands();
    this.getObc1Commands();
    this.getObc2Commands();
  },
  methods: {
    async getVersion2StaticCommands() {
      const { success, data } = await API.command.get('static');
      if (success)
      {
        this.version2Commands = data;
      }
    },
    async getObc1Commands() {
      const { success, data } = await API.command.getObc1Commands('static');
      if (success)
      {
        this.obs1Commands = data;
      }
    },
    async getObc2Commands() {
      const { success, data } = await API.command.getObc2Commands('static');
      if (success)
      {
        this.obs2Commands = data;
      }
    },
    async sendStaticCommand(commandId='') {
      this.isSubmiting = true;

      let params = {
        satelliteId: this.satelliteId,
        commandId,
      }

      if (!this.satelliteId)
      {
        this.$toast("Select a satelliteId", {
          timeout: 3000,
          type: "warning",
        })
        return
      }
      try {
        await API.station.sendStaticCommand(this.$route.params.id, params)
        this.$toast("The command was sent", {
          timeout: 3000,
          type: "success"
        });
      } catch (err) {
        console.log(JSON.stringify(err))
        this.$toast("An error occurred while sending the command", {
          timeout: 3000,
          type: "error"
        });
      }
      this.handleModalClose();
    },
    async sendCommandToObc1(commandId='') {
      this.isSubmiting = true;

      let params = {
        satelliteId: this.satelliteId,
        commandId,
      }

      if (!this.satelliteId)
      {
        this.$toast("Select a satelliteId", {
          timeout: 3000,
          type: "warning",
        })
        return
      }
      try {
        await API.station.sendCommandToObc1(this.$route.params.id, params)
        this.$toast("The command was sent", {
          timeout: 3000,
          type: "success"
        });
      } catch (err) {
        console.log(JSON.stringify(err))
        this.$toast("An error occurred while sending the command", {
          timeout: 3000,
          type: "error"
        });
      }
      this.handleModalClose();
    },
    async sendCommandToObc2(commandId='') {
      this.isSubmiting = true;

      let params = {
        satelliteId: this.satelliteId,
        commandId,
      }

      if (!this.satelliteId)
      {
        this.$toast("Select a satelliteId", {
          timeout: 3000,
          type: "warning",
        })
        return
      }
      try {
        await API.station.sendCommandToObc2(this.$route.params.id, params)
        this.$toast("The command was sent", {
          timeout: 3000,
          type: "success"
        });
      } catch (err) {
        console.log(JSON.stringify(err))
        this.$toast("An error occurred while sending the command", {
          timeout: 3000,
          type: "error"
        });
      }
      this.handleModalClose();
    },
    handleModalClose() {
      this.isSubmiting = false;
      this.satelliteId = '';
      this.selectedSatellite = null;
      this.$emit("sent");
      this.modal = false
    }
  },
  watch: {
    satelliteId() {
      let satellite = this.satellites.find(satellite => satellite.id === this.satelliteId)
      this.selectedSatellite = satellite
      this.version = satellite?.version;
    }
  }
}
</script>

<style>

</style>
