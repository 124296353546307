<template>
  <v-container>
    <!-- TODO: Check permission -->
    <v-row justify="end" v-if="user.permissions?.includes('GS.gs_admin_edit')" >
      <v-sheet class="ma-8">
        <v-btn color="primary" @click="openDialog">
          Create a task
        </v-btn>
      </v-sheet>
    </v-row>

    <!-- Users list table -->
    <v-row>
      <v-skeleton-loader
        :loading="isLoading"
        style="width: 100%;"
      >
        <ve-table
          class="ma-0 pr-8"
          :columns="columns"
          :table-data="taskSchedules"
          :scroll-width="0"
          :border-x="true"
          :border-y="true"
          style="width: 100%; word-break: break-all;"
        />
        <div class="table-pagination pr-4 pl-2">
          <ve-pagination :total="pagination.total" :page-index="page" :page-size="limit"
            :pageSizeOption="[5, 10, 20, 50]" @on-page-number-change="pageNumberChange"
            @on-page-size-change="pageSizeChange" />
        </div>
      </v-skeleton-loader>
    </v-row>

    <CreateTask :showDialog="showDialog" @closeDialog="closeDialog"></CreateTask>

    <!-- Confirm modal -->
    <template>
      <div class="text-center pa-4">
        <v-dialog
          v-model="isConfirmModalOpen"
          width="auto"
          max-width="400px"
        >
          <v-card >
            <v-card-title>Delete the task schedule</v-card-title>
            <v-card-text class="text-center">
              Are you sure you want to delete this task schedule?
            </v-card-text>
            <v-card-actions class="justify-space-around">
              <v-btn @click="deleteTaskSchedule" color="primary">Delete</v-btn>
              <v-btn @click="closeConfirmModal" color="secondary">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>

  </v-container>
</template>

<script>
  import store from '../../store';
  import CreateTask from './CreateTask.vue';

  import { useApi } from '@hooks/useApi';

  const taskScheduleAPI = useApi().taskSchedule


  export default {
    name: "TaskSchedules",
    components: {
      CreateTask
    },
    beforeMount() {
      this.getTaskSchedules();
    },
    data() {
      return {
        user: store.state.auth.user || {},

        isLoading: true,

        taskSchedules: [],
        isConfirmModalOpen: false,
        selectedDeletingTaskSchedule: null,
        columns: [
          {
            field: "satellite.name",
            key: "satellite",
            title: "Satellite",
            align: "center",
            renderBodyCell: ({ row }) => {
              return row.satellite.name
            }
          },
          {
            field: "station.name",
            key: "station",
            title: "Station",
            align: "center",
            renderBodyCell: ({ row }) => {
              return row.station.name
            }
          },
          {
            field: "startTime",
            key: "startTime",
            title: "Start Time",
            align: "center",
            renderBodyCell: ({ row }) => {
              return row.startTime
            }
          },
          {
            field: "endTime",
            key: "endTime",
            title: "End Time",
            align: "center",
            renderBodyCell: ({ row }) => {
              return row.endTime
            }
          },
          {
            field: "delayTime",
            key: "delayTime",
            title: "Delay Time",
            align: "center",
            renderBodyCell: ({ row }) => {
              return row.delayTime
            }
          },
          {
            field: "upLinkCommand",
            key: "upLinkCommand",
            title: "Up Link Command",
            align: "center",
            renderBodyCell: ({ row }) => {
              return row.upLinkCommand
            }
          },
          {
            field: "status",
            key: "status",
            title: "Status",
            align: "center",
            renderBodyCell: ({ row }) => {
              if (row.status === "running") {
                return (
                  <span class="mdi mdi-progress-download text-h4" style="color: #3498db"></span>
                )
              }
              else if (row.status === 'completed') {
                return (
                  <span class="mdi mdi-progress-check text-h4" style="color: #2ecc71"></span>
                )
              }
              else if (row.status === 'failed') {
                return (
                  <span class="mdi mdi-progress-close text-h4" style="color: red"></span>
                )
              }
              return (
                <span class="mdi mdi-progress-clock text-h4" style="color: #f1c40f"></span>
              )
            }
          },
          {
            field: "action",
            key: "action",
            title: "Action",
            align: "center",
            renderBodyCell: ({ row }) => {
              const { _id } = row
              return (
                <button
                  class="mdi mdi-trash-can-outline text-h5"
                  style="color: red"
                  onClick={() => this.openConfirmModal(_id)}
                >
                </button>
              )
            }
          },
        ],

        // initial variables for a data table
        page: 1,
        limit: 10,
        pagination: {
          start: 1,
          end: 1,
          total: 0,
          pageCount: 1,
          nextPage: 1,
          prevPage: 1,
        },

        showDialog: false,
      }
    },
    methods: {
      async getTaskSchedules() {
        this.isLoading = true;

        const { success, data, pagination, msg } = await taskScheduleAPI.get(this.page, this.limit, "-createdAt");
        if (success)
        {
          this.taskSchedules = data;
          this.pagination = pagination
        }

        this.isLoading = false;
      },
      // page number change
      pageNumberChange(pageIndex) {
        this.page = pageIndex;
        this.getTaskSchedules();
      },
      // page size change
      pageSizeChange(pageSize) {
        this.limit = pageSize
        this.getTaskSchedules()
      },
      openDialog() {
        this.showDialog = true;
      },
      closeDialog() {
        this.showDialog = false;
        this.getTaskSchedules();
      },
      openConfirmModal(_id) {
        this.isConfirmModalOpen = true;
        this.selectedDeletingTaskSchedule = _id;
      },
      closeConfirmModal() {
        this.isConfirmModalOpen = false;
        this.selectedDeletingTaskSchedule = null;
      },
      async deleteTaskSchedule() {
        const { success, msg } = await taskScheduleAPI.delete(this.selectedDeletingTaskSchedule)
        if (success) {
          this.isConfirmModalOpen = false
          this.getTaskSchedules()

          this.$toast.success(
            'Task schedule deleted successfully.',
            {
              type:'success',
              duration: 3000
            }
          )
        }
        else
        {
          this.$toast.warning(msg,
            {
              type:'warning',
              duration: 3000
            }
          )
        }
      }
    },
  }
</script>
