<template>
  <v-dialog v-model="modal" max-width="1010">
    <template v-slot:activator="{ on }">
      <v-btn class="mt-2 success" slot="activator" v-on="on" :disabled="station.status === 0 ? true : false">
        <v-icon class="mr-2">
          mdi-monitor
        </v-icon>
        Operation
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5 text-medium-emphasis">
          <v-icon class="mr-2">
            mdi-monitor
          </v-icon>
          Operation
        </div>
        <v-btn density="compact" icon @click="modal = false">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="mx-2 py-4" @submit.prevent>
          <h2>
            Automatic Tuning
            <v-tooltip
              location="end"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  icon
                  v-bind="props"
                >
                  <v-icon color="grey-lighten-1" size="12">
                    mdi-help
                  </v-icon>
                </v-btn>
              </template>
              <span color="white">Programmatic tooltip</span>
            </v-tooltip>
          </h2>

          <v-row justify="center">
            <v-col cols="6">
              <v-select
                clearable
                label="Auto Tune"
                :items="autoTunes"
                item-text="title"
                item-value="value"
                variant="outlined"
                :rules="autoTuneRules"
                v-model="autoTune"
              ></v-select>
            </v-col>
          </v-row>

          <h2 class="py-3">Manual Tuning</h2>

          <v-row class="d-flex justify-space-around">
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-select
                label="Satellite Name"
                :items="satellites"
                item-text="name"
                item-value="id"
                variant="outlined"
                append-icon="mdi-help-circle"
                :disabled="false"
                v-model="selectedSatId"
              >
                <template v-slot:append-icon>
                  <v-icon size="4">mdi-help-circl</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="true"
                label="NORAD"
                :value="getConfiguration?.NORAD"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="true"
                label="Frequency"
                :value="getConfiguration?.freq"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="true"
                label="Mode"
                :value="getConfiguration?.mode"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="true"
                label="Spreading Factor"
                :value="getConfiguration?.sf"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="true"
                label="Bandwidth"
                :value="getConfiguration?.bw"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="true"
                label="Coding Rate"
                :value="getConfiguration?.cr"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="true"
                label="Syncword"
                :value="getConfiguration?.sw"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="true"
                label="CRC"
                :value="getConfiguration?.crc"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="true"
                label="Preamble length"
                :value="getConfiguration?.pl"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="true"
                label="FLDRO"
                :value="getConfiguration?.fldro"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="true"
                label="Current gain"
                :value="getConfiguration?.gain"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="true"
                label="TX Power"
                :value="getConfiguration?.pwr"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="6">
              <v-btn class="mt-2 primary" type="submit" block @click="submitForm" :loading="isSubmitLoading">Submit</v-btn>
            </v-col>
          </v-row>

        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { useApi } from '@hooks/useApi';

  const API = useApi()

  export default {
    name: "Operation",
    props: [
      "station",
      "refresh",
    ],
    components: {
    },
    mounted() {
      this.setValues()
      this.getSatellites()
    },
    data() {
      return {
        modal: null,
        isSubmitLoading: false,  // submit loader

        satellites: null,
        selectedSatId: null,
        selectedSatellite: null,

        autoTunes: [
          {
            title: "Enabled 137",
            value: 137
          },
          {
            title: "Enabled 433",
            value: 433
          },
          {
            title: "Enabled 868-915",
            value: 900
          },
          {
            title: "Enabled 2.4G",
            value: 2400
          },
          {
            title: "Disabled",
            value: 0,
          },
        ],
        autoTune: 0,
        autoTuneRules: [
          value => {
            this.autoTune = value
            return true;
          }
        ],

        configurationRules: [
          value => {
            return true
          }
        ]
      }
    },
    watch: {
      autoTune(neValue) {
        this.selectedSatellite = null
        this.getSatellites()
      },
      selectedSatId(neValue) {
        this.getSatellite()
      }
    },
    methods: {
      setValues() {
        this.selectedSatId = this.station?.satellite?._id
      },
      async getSatellites() {
        const { success, data } = await API.satellite.get('version name callSign satId status configuration', this.autoTune)
        if (success)
        {
          this.satellites = data
        }
      },
      async getSatellite() {
        const { success, data } = await API.satellite.getOnce(this.selectedSatId)
        if (success)
        {
          this.selectedSatellite = data
        }
      },
      async submitForm() {
        this.isSubmitLoading = true
        const body = {
          satelliteId: this.selectedSatId,
          autoTune: this.autoTune,
        }

        const { success, data, msg } = await API.station.updateAutoTune(this.station._id, body)
        this.isSubmitLoading = false
        if (success)
        {
          this.refresh()
          this.$toast(data, {
            timeout: 3000,
            type: "success"
          });
          this.modal = false
        }
        else
        {
          this.$toast(msg, {
            timeout: 3000,
            type: "warning"
          });
        }
      },
    },
    computed: {
      getConfiguration() {
        const configuration = this.selectedSatellite?.configuration
        return configuration
      },
    }

  }

</script>
