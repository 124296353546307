<template>
  <RightModal :showDialog="showDialog" :closeDialog="closeDialog">
    <template v-slot:title>
      Create a task
    </template>

    <v-form class="px-5 pb-12" @submit.prevent="handleSubmit">
      <v-select
        v-model="selectedSatellite"
        :items="satellites"
        item-text="name"
        item-value="_id"
        label="Select a satellite"
        required
        clearable
      >
      </v-select>

      <v-select
        v-model="selectedStation"
        :items="stations"
        item-text="name"
        item-value="_id"
        label="Select a station"
        required
        clearable
      >
      </v-select>

      <v-text-field
        v-model="upLinkCommand.value"
        :error-messages="upLinkCommand.error"
        label="Up Link Command"
        clearable
      ></v-text-field>

      <v-text-field
        v-model="delayTime.value"
        :error-messages="delayTime.error"
        label="Delay"
        clearable
        type="number"
        min="5"
      ></v-text-field>

      <v-row no-gutters>
        <v-label>
          Start date
        </v-label>
        <v-date-picker width="100%" class="pt-4" color="primary" v-model="startDate"></v-date-picker>

        <v-label class="pt-4">
          Start time
        </v-label>
        <v-time-picker title="Start time" v-model="startTime"></v-time-picker>
      </v-row>

      <v-row no-gutters class="pt-5">
        <v-label>
          End date
        </v-label>
        <v-date-picker width="100%" class="pt-4" color="primary" v-model="endDate"></v-date-picker>

        <v-label class="pt-4">
          End time
        </v-label>
        <v-time-picker title="Start time" v-model="endTime"></v-time-picker>
      </v-row>

      <v-row justify="center" class="px-4 pt-8">
        <v-btn class="primary" type="submit" :loading="isLoading" block>
          Submit
        </v-btn>
      </v-row>

    </v-form>

    </RightModal>
</template>

<script>
  import RightModal from '@components/Modal/RightModal.vue';

  import { useApi } from '@hooks/useApi';

  const API = useApi()

  export default {
    name: "CreateTask",
    components: {
      RightModal,
    },
    props: {
      showDialog: Boolean,
    },
    beforeMount() {
      this.getSatellites();
      this.getStations();
    },
    data() {
      return {
        isLoading: false,
        upLinkCommand: {
          value: null,
          error: null,
        },
        delayTime: {
          value: null,
          error: null,
        },
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,

        satellites: null,
        selectedSatellite: null,
        stations: null,
        selectedStation: null,
      }
    },
    methods: {
      closeDialog() {
        this.$emit('closeDialog');
      },
      async getSatellites() {
        const select = 'name configuration'
        const { success, data, msg } = await API.satellite.get(select)

        if (success) {
          this.satellites = data
        }
      },
      async getStations() {
        // TODO: That API using data table
        const page = 1
        const limit = 100
        const sort = "name"
        const search = ''
        const select = "name"
        const { success, data, msg } = await API.station.get(page, limit, sort, search)

        if (success) {
          this.stations = data
        }
      },
      async handleSubmit() {
        this.isLoading = true;

        const _startDate = new Date(`${this.startDate}T${this.startTime}`)
        const _endDate = new Date(`${this.endDate}T${this.endTime}`)

        if (_startDate < _endDate)
        {
          const body = {
            satelliteId: this.selectedSatellite,
            stationId: this.selectedStation,
            upLinkCommand: this.upLinkCommand.value,
            startTime: _startDate,
            endTime: _endDate,
            delayTime: this.delayTime.value,
          }

          const { success, data, msg } = await API.taskSchedule.post(body)
          if (success)
          {
            this.$toast(msg, {
              type: "success",
              duration: 3000,
            })
            this.closeDialog();
          }
          else
          {
            this.$toast(msg, {
              type: "warning",
              duration: 3000,
            })
          }
        }
        else
        {
          this.$toast("Start date must be then greater End date", {
            type: "warning",
            duration: 3000,
          })
        }

        this.isLoading = false;
      }
    }
  }
</script>
