<template>
  <div class="rock-block">
    <v-container>
      <v-row justify="end" v-if="user.permissions?.includes('GS.gs_admin_edit')">
        <v-sheet class="ma-8">
          <v-btn color="primary" @click="openModal">
            Send a message
          </v-btn>
        </v-sheet>
      </v-row>

      <!-- Users list table -->
      <v-skeleton-loader
        :loading="isLoading"
        style="width: 100%;"
      >
        <ve-table
          class="ma-0 pr-8"
          :columns="columns"
          :table-data="rockBlocks"
          :scroll-width="0"
          :border-x="true"
          :border-y="true"
          style="width: 100%; word-break: break-all;"
        />
        <div class="table-pagination pr-4 pl-2">
          <ve-pagination :total="pagination.total" :page-index="page" :page-size="limit"
            :pageSizeOption="[5, 10, 20, 50]" @on-page-number-change="pageNumberChange"
            @on-page-size-change="pageSizeChange" />
        </div>
      </v-skeleton-loader>

      <!-- Create RockBlock -->
      <SendMessage :show-dialog="showDialog" @closeDialog="closeDialog" />

      <!-- View data -->
      <ViewRockBlock :show-dialog="isViewOpen" :message="this.message" @closeDialog="closeViewDialog" />
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");

import store from '../../store';
import { useApi } from '../../hooks/useApi';

import SendMessage from './SendMessage.vue';
import ViewRockBlock from './ViewRockBlock.vue';

const rockBlockApi = useApi().rockBlock

const dataSize = {
  "xs": 5,
  "sm": 5,
}

export default {
  name: "RockBlock",
  components: {
    SendMessage,
    ViewRockBlock
  },
  computed: {
    user: function () {
      return store.state.auth.user
    }
  },
  data() {
    return {
      showDialog: false,
      isLoading: true,

      isViewOpen: false,
      message: '',

      // initial variables for a data table
      pagination: {
        start: 1,
        end: 1,
        total: 0,
        pageCount: 1,
        nextPage: 1,
        prevPage: 1,
      },
      page: 1,
      limit: 10,

      rockBlocks: [],
      columns: [
        {
          field: "imei",
          key: "imei",
          title: "IMEI",
          align: "center",
          width: "150px",
        },
        {
          field: "serial",
          key: "serial",
          title: "SERIAL",
          align: "center",
          width: "100px",
        },
        {
          field: "momsn",
          key: "momsn",
          title: "MOMSN",
          align: "center",
          width: "100px",
        },
        {
          field: "transmit_time",
          key: "transmit_time",
          title: "TRANSMIT TIME",
          align: "center",
          width: "150px",
        },
        {
          field: "iridium_latitude",
          key: "iridium_latitude",
          title: "LATITUDE",
          align: "center",
          width: "100px",
        },
        {
          field: "iridium_longitude",
          key: "iridium_longitude",
          title: "LONGITUDE",
          align: "center",
          width: "100px",
        },
        {
          field: "iridium_cep",
          key: "iridium_cep",
          title: "CEP",
          align: "center",
          width: "80px",
        },
        {
          field: "data",
          key: "data",
          title: "DATA",
          align: "center",
          width: "200px",
          renderBodyCell: ({ row }) => {
            let data = row.data
            if (data.length > 50) {
              let first50 = data.slice(0, 50)
              return `${first50}...`
            }
            return data
          }
        },
        {
          field: "view",
          key: "view",
          title: "ACTIONS",
          align: "center",
          width: "200px",
          renderBodyCell: ({ row }) => {
            return <button class="btn" onClick={() => this.viewText(row.data)}>
              View
            </button>
          }
        },
      ],
    }
  },
  beforeMount() {
    let displaySize = this.$vuetify.breakpoint.name
    this.limit = dataSize[displaySize]
    this.getRockBlock();
  },
  methods: {
    // page number change
    pageNumberChange(pageIndex) {
      this.page = pageIndex;
      this.getRockBlock();
    },

    // page size change
    pageSizeChange(pageSize) {
      this.limit = pageSize
      this.getRockBlock()
    },
    openModal() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    closeViewDialog() {
      this.isViewOpen = false
    },
    /**
     * Rockblock-ийн жагсаалтыг авах функц
     */
    async getRockBlock() {
      this.isLoading = true;
      try {
        const { data, pagination } = await rockBlockApi.get(this.page, this.limit);

        this.rockBlocks = data;
        this.isLoading = false;
        this.pagination = pagination;

      } catch (err) {
        console.error(err);
      }
    },
    hexToString(hexData) {
      let string = '';
      if (hexData) {
        for (let i = 0; i < hexData.length; i += 2) {
          string += String.fromCharCode(parseInt(hexData.substr(i, 2), 16));
        }
      }
      return string;
    },
    viewText(data) {
      this.message = data
      this.isViewOpen = true
    }
  },
}
</script>

<style>
.ve-table {
  margin: 0 16px;
}

.table-pagination {
  margin-top: 20px;
  text-align: right;
}

.ve-table-body-tr {
  margin-right: 18px;
}

.ve-pagination {
  height: auto !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.badge {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: bold;
  color: white;
}

.primary {
  background-color: #ff0099;
}

.warning {
  background-color: #ffcc00;
}

.btn {
  background-color: #ff0099;
  padding: 4px 16px 4px 16px;
  border-radius: 4px;
  text-transform: uppercase;
}
</style>
