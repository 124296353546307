import axios from "axios";

import store from "../store";

const allowedLocations = [
  '/user/confirm/'
]


export function useApi() {
  const source = axios.CancelToken.source();
	const instance = axios.create({
		baseURL: process.env.VUE_APP_API_BASE_URL,
		withCredentials: true, // Хүсэлт болгонд ээр cookie явуулах нь
		cancelToken: source.token,
	});

  instance.interceptors.request.use(async conf => {
    return conf
  })

  instance.interceptors.response.use(
    function(rsp) {
      const data = rsp?.data;

      return data
    },
    function(rsp) {
      const { status, data } = rsp.response

      if ([401, 403].indexOf(status) !== -1)
      {
        var isLogout = true
        allowedLocations.forEach(allowedLocation => {
          if (window.location.pathname.includes(allowedLocation)) {
            isLogout = false
            return
          }
        })

        if (isLogout) {
          store.dispatch('logOut')
          if (window.location.pathname !== "/") {
            window.location.href = "/"
          }
        }
      }
      else if ([400].indexOf(status) !== -1)
      {
        return data
      }
      else
      {
        let returnData = {
          success: false,
          msg: data.message,
        }
        return returnData;
      }

      return Promise.reject(rsp);
    }
  )

  // Return any values or functions you want to expose from the hook
  return {
    source,
    instance,

    satellite: {
      get: (select='', frequency='') => instance.get(`api/v1/satellites?select=${select}&frequency=${frequency}`),
      getOnce: satId => instance.get(`api/v1/satellites/${satId}`),
      getStatistics: satId => instance.get(`api/v1/satellites/${satId}/statistic`),
      getVoltCharStatistics: satId => instance.get(`api/v1/satellites/${satId}/volt-statistics`),
      getBatCharStatistics: satId => instance.get(`api/v1/satellites/${satId}/bat-char-statistics`),
      getPackets: (satId, page) => instance.get(`api/v1/satellites/${satId}/packets?page=${page}`),
      post: data => instance.post('api/v1/satellites/', data),
      put: (id, data) => instance.put('api/v1/satellites/' + id, data),
    },
    station: {
      get: (page, limit, sort, search, select) => instance.get(`api/v1/stations/?page=${page}&limit=${limit}&sort=${sort}&search=${search}&select=${select}`),
      getStation: (stationId) => instance.get(`api/v1/stations/${stationId}`),
      updateStation: (stationId, body) => instance.put(`api/v1/stations/${stationId}`, body),
      getPackets: (stationId, page) => instance.get(`api/v1/stations/${stationId}/packets?page=${page}`),
      updateGSMData: (stationId) => instance.get(`api/v1/stations/gsm/${stationId}`),
      updateAutoTune: (stationId, data) => instance.put(`api/v1/stations/auto-tune/${stationId}`, data),

      sendStaticCommand: (stationId, data) => instance.post(`api/v1/stations/${stationId}/send-static-command`, data),
      sendDynamicCommand: (stationId, data) => instance.post(`api/v1/stations/${stationId}/send-dynamic-command`, data),
      sendHexCommand: (stationId, data) => instance.post(`api/v1/stations/${stationId}/send-hex-command`, data),
      sendCommandToObc1: (stationId, data) => instance.post(`api/v1/stations/${stationId}/send-static-command/obc-1`, data),
      sendCommandToObc2: (stationId, data) => instance.post(`api/v1/stations/${stationId}/send-static-command/obc-2`, data),
      sendDynamicCommandOwl1: (stationId, data) => instance.post(`api/v1/stations/${stationId}/send-dynamic-command-owl-version-1`, data),
    },
    packet: {
      get: (page=1, limit=10, sort='') => instance.get(`api/v1/packet-tinygs?page=${page}&limit=${limit}&sort=${sort}`),
      getOnce: id => instance.get(`api/v1/packet-tinygs/${id}`),
      getVersion_2: (page, limit) => instance.get(`api/v2/packets/?page=${page}&limit=${limit}`),  // NOTE: Not using
    },
    rockBlock: {
      get: (page=1, limit=10, sort='') => instance.get(`api/v1/rock-blocks/?page=${page}&limit=${limit}&sort=${sort}`),

      sendMessage: {
        post: data => instance.post('api/v1/rock-blocks/send-message/', data),

      }
    },
    v2: {
      satellite: {
        get: () => instance.get('api/v2/satellites/'),
        post: data => instance.post('api/v2/satellites/', data),
        put: (id, data) => instance.put('api/v2/satellites/' + id, data),
      }
    },
    commandLog: {
      get: (page, limit, sort, search, select='') => instance.get(`api/v1/command-log?page=${page}&limit=${limit}&sort=${sort}&search=${search}&select=${select}`),
    },
    command: {
      get: commandType => instance.get(`api/v1/command?type=${commandType}`),
      getObc1Commands: commandType => instance.get(`api/v1/command/obc-1?type=${commandType}`),
      getObc2Commands: commandType => instance.get(`api/v1/command/obc-2?type=${commandType}`)
    },
    acknowledgeLog: {
      get: (page, limit, sort, search, select='') => instance.get(`api/v1/acknowledge-log?page=${page}&limit=${limit}&sort=${sort}&search=${search}&select=${select}`),
    },
    houseKeepingPacket: {
      get: (page=1, limit=10, sort='') => instance.get(`api/v1/house-keeping-packets?page=${page}&limit=${limit}&sort=${sort}`),
    },
    taskSchedule: {
      get: (page=1, limit=10, sort='') => instance.get(`api/v1/cron-jobs?page=${page}&limit=${limit}&sort=${sort}`),
      post: (data) => instance.post(`api/v1/cron-jobs`, data),
      delete: (id) => instance.delete(`api/v1/cron-jobs/${id}`),
    },
  };
}
